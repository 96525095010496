import * as React from "react"
import {
  ConnectedSourceTargetVariants,
  OrgTargetTypes,
} from "@modules/organization/shared/constants"

export const getVariantFromSourceControlData = (
  sourceTargets: { id: string; targetType: string; name: string }[]
) => {
  if (sourceTargets.length === 0) {
    return ConnectedSourceTargetVariants.NotConnectedYet
  } else if (sourceTargets.length >= 2) {
    return ConnectedSourceTargetVariants.MultipleTargets
  }

  // We know that there is exactly 1 target, since all other possibilities
  // are accounted for. We just need to establish what its type is.
  const [singleTarget] = sourceTargets

  return singleTarget.targetType === OrgTargetTypes.PersonalAccount
    ? ConnectedSourceTargetVariants.PersonalUser
    : ConnectedSourceTargetVariants.SingleOrgMember
}

export const getCopyForAddTarget = (
  variant: string,
  currentSourceTargetName: string
) => {
  switch (variant) {
    case ConnectedSourceTargetVariants.PersonalUser:
      return (
        <React.Fragment>
          We&apos;ll create this repo under your personal GitHub account,{" "}
          <strong>{currentSourceTargetName}</strong>. Want to host it on a
          GitHub organization instead?
        </React.Fragment>
      )
    case ConnectedSourceTargetVariants.SingleOrgMember:
      return (
        <React.Fragment>
          We&apos;ll create this repo under the{" "}
          <strong>{currentSourceTargetName}</strong> organization. Want to host
          it on a GitHub organization instead?
        </React.Fragment>
      )

    default:
    case ConnectedSourceTargetVariants.MultipleTargets:
      return (
        <React.Fragment>
          Don&apos;t see the right account or organization?
        </React.Fragment>
      )
  }
}

export const sortListOfSourceTargets = (
  sourceTargets: { targetType: string; id: string; name: string }[]
) => {
  // Sort the source targets so that if the user has a personal account,
  // it rises to the top of the list
  const personalAccountIndex = sourceTargets.findIndex(
    target => target.targetType === OrgTargetTypes.PersonalAccount
  )

  if (personalAccountIndex === -1) {
    return sourceTargets
  }

  return [
    sourceTargets[personalAccountIndex],
    ...sourceTargets.slice(0, personalAccountIndex),
    ...sourceTargets.slice(personalAccountIndex + 1),
  ]
}
