import * as React from "react"
import { Form as FormikForm, FormikConfig, FormikHelpers } from "formik"
import Form from "@modules/form/components/Form"
import * as Yup from "yup"
import { SourceControlProvider, CmsVendor } from "@modules/graphql/types"
import { deployNow as text } from "@modules/locales/default.js"
import Loading from "@modules/ui/components/Loading"
import { ZeroGithubInstallations } from "@modules/organization/shared/components/ZeroGithubInstallations"
import useInstallationWindow from "@modules/toolkit/closable-window/useInstallationWindow"
import InstallationWindowLoader from "@modules/toolkit/closable-window/InstallationWindowLoader"
import { useUserInstallsQuery } from "@modules/site/create/DeployNow/queries.generated"
import { useValidateUniqueSiteName } from "@modules/site/shared/hooks/useValidateUniqueSiteName"
import { useUserOrganizationsQuery } from "@modules/header/queries.generated"
import {
  ErrorAlert,
  useTriggerErrorAlert,
} from "@modules/ui/components/ErrorAlert"
import { navigate } from "gatsby"
import { SiteInitStatus } from "@modules/graphql/types"

import { PickSourceTargetContents } from "./PickSourceTargetContents"
import { sortListOfSourceTargets } from "./PickSourceTarget.helpers"
import {
  useCreateSiteFromUrlMutation,
  useSiteInitJobChangedSubscription,
} from "@modules/onboarding/gaFlow/queries.generated"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { Button } from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"
import { getPathToDeployNowSite } from "@modules/site/create/shared/utils"
import { useSetShopifyAppIntegration } from "@modules/cms/shared/hooks/useSetShopifyApp"
import { usePublishSiteMutation } from "../../Import/queries.generated"
import { useUserOrganizationsBySites } from "@modules/site/transfer/hooks/useUserOrganizationsBySites"
import { PlatformLimitInfoModal } from "@modules/organization/list/components/PlatformLimitInfoModal"

type FormValues = {
  organizationId: string
  installation: string
  repoName: string
}

const validationSchema = Yup.object().shape<FormValues>({
  installation: Yup.string().required(
    `You must select an installation to continue`
  ),
  repoName: Yup.string().required(`Enter a repo name`),
  organizationId: Yup.string().required(
    `You must select an organization to continue`
  ),
})

export type PickSourceTargetProps = {
  repoName: string
  repoUrl: string
  frameable?: boolean
  frameableCmsVendor?: CmsVendor
}

export function PickSourceTarget({
  repoName,
  repoUrl,
  frameable,
  frameableCmsVendor,
}: PickSourceTargetProps) {
  const repoNameRef = React.useRef(repoName)
  const organizationIdRef = React.useRef(``)
  const [isShowingInstallation, setShowingInstallation] = React.useState(false)
  const [hasClosedWindow, setHasClosedWindow] = React.useState(false)
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [initStatus, setInitStatus] = React.useState(SiteInitStatus.InProgress)
  const [initStatusError, setInitStatusError] = React.useState<
    string | undefined | null
  >()

  const [
    targetOrgWithNoRoomForAnotherSite,
    setTargetOrgWithNoRoomForAnotherSite,
  ] = React.useState<{
    id: string
    sitesQuantity?: number
  } | null>(null)

  const statusMessages = new Map([
    [SiteInitStatus.CloningRepo, text.messages.cloningRepo],
    [SiteInitStatus.CreatingSite, text.messages.creatingSite],
    [SiteInitStatus.InProgress, text.messages.creatingRepository],
    [SiteInitStatus.PushingRepo, text.messages.pushingRepository],
    [SiteInitStatus.Success, text.messages.success],
  ])

  const {
    data: userOrganizationsData,
    loading: userOrganizationsLoading,
  } = useUserOrganizationsQuery()
  const organizations = userOrganizationsData?.currentOrganizations || []
  const defaultOrganization = organizations[0]
  if (defaultOrganization && !organizationIdRef.current) {
    organizationIdRef.current = defaultOrganization.id
  }

  // provides data about workspaces site limit and its usage
  const organizationsBySiteUsage = useUserOrganizationsBySites(organizations, {
    skip: !organizations,
  })

  const { pollInterval, popInstallationWindow } = useInstallationWindow(() => {
    setHasClosedWindow(true)
    setShowingInstallation(false)
  })

  const { data: userInstallsData, loading, error } = useUserInstallsQuery({
    pollInterval,
  })

  const {
    setShopifyAppIntegration: setShopifyApp,
    clearShopifyStorageInfo,
  } = useSetShopifyAppIntegration()
  const [publishSiteMutation] = usePublishSiteMutation()

  const rawSourceTargets = userInstallsData?.installationsForUser || []
  const sourceTargets = sortListOfSourceTargets(rawSourceTargets)
  const defaultOrganizationInstallation = sourceTargets[0]

  const [verifySiteNameUniqueness] = useValidateUniqueSiteName()
  const [
    createSiteFromUrlMutation,
    { data: createSiteData, loading: createSiteLoading },
  ] = useCreateSiteFromUrlMutation()

  const siteInitJob = createSiteData?.createSiteFromUrl?.siteInitJob

  useSiteInitJobChangedSubscription({
    variables: {
      id: siteInitJob?.id || "",
    },
    skip: !siteInitJob,
    onSubscriptionData: ({ subscriptionData }) => {
      const siteInitJobChanged = subscriptionData?.data?.siteInitJobChanged
      if (siteInitJobChanged?.status) {
        setInitStatus(siteInitJobChanged?.status)
      }
      if (siteInitJobChanged?.status === `FAILED`) {
        setInitStatusError(siteInitJobChanged.error || "Unable to create site")
      }
      if (
        siteInitJobChanged?.status === `SUCCESS` &&
        siteInitJobChanged?.siteId &&
        organizationIdRef.current
      ) {
        if (frameable && frameableCmsVendor === CmsVendor.Shopify) {
          return setShopifyApp(siteInitJobChanged?.siteId)
            .then(() => {
              return publishSiteMutation({
                variables: {
                  id: siteInitJobChanged?.siteId,
                  environmentVariables: [],
                },
              })
            })
            .then(result => {
              if (result?.data?.publishSite) {
                clearShopifyStorageInfo()
                return window.close()
              }
            })
            .catch(e => {
              console.error(e)
            })
        }

        navigate(
          `${getPathToDeployNowSite(organizationIdRef.current)}/${
            siteInitJobChanged.siteId
          }/integrations`
        )
      }
    },
  })

  // display any errors
  if (error || initStatusError || organizationsBySiteUsage.error) {
    const message = error?.message || initStatusError
    return <ErrorAlert>{message}</ErrorAlert>
  }

  // Load the user installations
  if (loading || userOrganizationsLoading || organizationsBySiteUsage.loading) {
    return (
      <Loading
        delay={1000}
        message={`Loading your organizations...`}
        bufferSize="padded"
      />
    )
  }

  // Display a message when organisations are empty
  if (isShowingInstallation) {
    return (
      <InstallationWindowLoader
        message={text.messages.openWindowGithubAuthentication}
        onPopInstallationWindow={() =>
          popInstallationWindow(text.messages.addIt, {
            uiSource: `Organization Selection`,
          })
        }
      />
    )
  }

  // Window has been closed, display "wait a moment" thing
  if (hasClosedWindow && sourceTargets.length === 0) {
    return (
      <InstallationWindowLoader
        message={text.messages.waitAMoment}
        onPopInstallationWindow={() =>
          popInstallationWindow(text.messages.addIt, {
            uiSource: `Organization Selection`,
          })
        }
      />
    )
  }

  // No github organizations associated with the user
  if (sourceTargets.length === 0) {
    return (
      <ZeroGithubInstallations
        popInstallationWindow={popInstallationWindow}
        setOrgLoading={setShowingInstallation}
      />
    )
  }

  // checks if the selected workspace has room to create another site on it
  const orgHasRoomForAnotherSite = (id: string) => {
    const org = organizationsBySiteUsage.withNoRoom?.find(org => org.id === id)

    if (org) {
      setTargetOrgWithNoRoomForAnotherSite({
        id,
        sitesQuantity: org.usage?.taken,
      })

      return false
    }

    return true
  }

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    setTargetOrgWithNoRoomForAnotherSite(null)
    orgHasRoomForAnotherSite(e.target.value)
  }

  const preSubmitHandle = (
    e: React.FormEvent<HTMLFormElement>,
    values: FormValues,
    submitCallback: () => void
  ) => {
    e.preventDefault()

    setTargetOrgWithNoRoomForAnotherSite(null)
    const hasRoomForSite = orgHasRoomForAnotherSite(values.organizationId)

    if (hasRoomForSite) {
      return submitCallback()
    }
  }

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    actions.setSubmitting(true)

    verifySiteNameUniqueness({
      name: values.repoName,
      sourceOrganizationId: values.installation,
      organizationId: organizationIdRef.current,
      sourceControlProvider: SourceControlProvider.Github,
    }).then((isValid: boolean) => {
      if (isValid) {
        createSiteFromUrlMutation({
          variables: {
            workspaceId: organizationIdRef.current,
            selectedInstallationId: values.installation,
            repoUrl,
            targetRepoName: values.repoName,
          },
        }).then(({ data }) => {
          if (data?.createSiteFromUrl?.validation?.success === false) {
            setError({
              message:
                data?.createSiteFromUrl?.validation?.message ||
                "Unable to create site",
            })
            actions.setSubmitting(false)
          }
        })
      } else {
        setError({ message: "Project name already taken." })
        actions.setSubmitting(false)
      }
    })
  }

  return (
    <React.Fragment>
      <Form
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{
          repoName: repoNameRef.current,
          organizationId: organizationIdRef.current,
          installation: defaultOrganizationInstallation
            ? defaultOrganizationInstallation.id
            : "",
        }}
      >
        {({ isSubmitting, values, ...rest }) => {
          repoNameRef.current = values.repoName
          organizationIdRef.current = values.organizationId

          const currentTarget = sourceTargets.find(
            org => org.id === values.installation
          )

          if (isSubmitting || createSiteLoading) {
            return (
              <Loading
                message={statusMessages.get(initStatus)}
                bufferSize="padded"
              />
            )
          }

          return (
            <FormikForm
              onChange={handleChange}
              onSubmit={e =>
                preSubmitHandle(e, values, () => handleSubmit(values, rest))
              }
            >
              {errorAlert}
              <PickSourceTargetContents
                sourceTargets={sourceTargets}
                currentSourceTarget={currentTarget}
                organizations={organizations}
                repoName={values.repoName}
                onSelectInstallation={() => {
                  setShowingInstallation(true)
                  popInstallationWindow(text.messages.addIt, {
                    uiSource: `Repository Selection`,
                  })
                }}
              />
              <WizardFooter
                goBackButton={<div />}
                goNextButton={
                  values.repoName &&
                  values.installation &&
                  values.organizationId && (
                    <Button type="submit">Next{<MdArrowForward />}</Button>
                  )
                }
              />
            </FormikForm>
          )
        }}
      </Form>
      <PlatformLimitInfoModal
        isOpen={Boolean(targetOrgWithNoRoomForAnotherSite)}
        onDismiss={() => setTargetOrgWithNoRoomForAnotherSite(null)}
        sitesQuantity={targetOrgWithNoRoomForAnotherSite?.sitesQuantity}
        organizationId={targetOrgWithNoRoomForAnotherSite?.id}
      />
    </React.Fragment>
  )
}
