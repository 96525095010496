import * as React from "react"
import { useField } from "formik"
import {
  Button,
  InputConnectedField,
  Notification,
  StyledLabelSize,
  Theme,
  useConnectedField,
  SelectFieldBlock,
} from "gatsby-interface"
import {
  deployNow as deployNowText,
  provisionSite as text,
} from "@modules/locales/default.js"
import { SourceInstallationField } from "@modules/organization/shared/components/SourceInstallationField"
import { ConnectedSourceTargetVariants } from "@modules/organization/shared/constants"
import { FadeOnMount } from "@modules/animation"
import formatRepoName from "@modules/toolkit/helpers/formatRepoName"
import {
  getVariantFromSourceControlData,
  getCopyForAddTarget,
} from "./PickSourceTarget.helpers"

const groupCss = (theme: Theme) => ({
  display: `grid`,
  gridGap: theme.space[7],
})

function OrganizationsField({
  name,
  organizations,
  label,
  labelSize,
}: {
  name: string
  organizations: { id: string; name: string }[]
  label: string
  labelSize: StyledLabelSize | undefined
}) {
  const [{ value: currentValue, ...connectedProps }] = useConnectedField(name)

  return (
    <React.Fragment>
      <SelectFieldBlock
        {...connectedProps}
        label={label}
        options={organizations.map(organization => {
          return {
            value: organization.id,
            label: organization.name,
          }
        })}
        labelSize={labelSize}
      />
    </React.Fragment>
  )
}

function AddTargetBlurb({
  variant,
  currentSourceTargetName,
  onSelectInstallation,
}: {
  variant: string
  currentSourceTargetName: string
  onSelectInstallation: () => void
}) {
  const copy = getCopyForAddTarget(variant, currentSourceTargetName)

  if (!copy) {
    return null
  }

  return (
    <Notification
      variant="SECONDARY"
      tone="NEUTRAL"
      content={
        <div>
          {copy}
          {` `}
          <Button
            variant="GHOST"
            css={theme => ({
              padding: `0`,
              textDecoration: `underline`,
              fontFamily: theme.fonts.body,
              fontSize: "inherit",

              ":hover": {
                background: "transparent",
              },
            })}
            onClick={e => {
              e.preventDefault()
              onSelectInstallation()
            }}
          >
            {text.addIt}
          </Button>
        </div>
      }
    />
  )
}

function RepoNameField(props: {
  name: string
  label: string
  labelSize: StyledLabelSize | undefined
  "data-cy": string
}) {
  const [, , helpers] = useField("repoName")
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <InputConnectedField
      {...props}
      ref={inputRef}
      onChange={e => {
        helpers.setValue(formatRepoName(e.target.value))
      }}
      // This zIndex is needed to prevent input visually colliding with the fixed footer
      css={{ zIndex: 0 }}
    />
  )
}

export type PickSourceTargetContentsProps = {
  sourceTargets: { id: string; targetType: string; name: string }[]
  currentSourceTarget?: { name: string }
  onSelectInstallation: () => void
  repoName: string
  organizations: { id: string; name: string }[]
}

export function PickSourceTargetContents({
  sourceTargets,
  currentSourceTarget,
  onSelectInstallation,
  repoName,
  organizations,
}: PickSourceTargetContentsProps) {
  const variant = getVariantFromSourceControlData(sourceTargets)
  const labelSize = `L`

  return (
    <React.Fragment>
      <div css={groupCss}>
        <OrganizationsField
          organizations={organizations}
          name="organizationId"
          label={deployNowText.labels.whichWorkspace}
          labelSize={labelSize}
        />
        <RepoNameField
          name="repoName"
          label={deployNowText.labels.repoName}
          labelSize={labelSize}
          data-cy="new-repo-name"
        />

        {Boolean(repoName) && (
          <FadeOnMount css={groupCss}>
            {variant === ConnectedSourceTargetVariants.MultipleTargets && (
              <SourceInstallationField
                name="installation"
                label={text.chooseDestination}
                labelSize={labelSize}
                organizations={sourceTargets}
              />
            )}

            {sourceTargets.length > 0 && (
              <React.Fragment>
                <AddTargetBlurb
                  variant={variant}
                  currentSourceTargetName={currentSourceTarget?.name || ""}
                  onSelectInstallation={onSelectInstallation}
                />
              </React.Fragment>
            )}
          </FadeOnMount>
        )}
      </div>
    </React.Fragment>
  )
}
