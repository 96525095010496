import * as React from "react"
import {
  Button,
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  ThemeCss,
} from "gatsby-interface"
import { navigate } from "gatsby"
import {
  getPathToOrgPricing,
  getPathToOrgSettings,
} from "@modules/organization/shared/utils"
import { useOrganizationPermissionsFieldsFragment } from "@modules/organization/fragments.generated"

export type PlatformLimitInfoModalProps = {
  organizationId?: string
  isOpen: boolean
  onDismiss: (val: boolean) => void
  sitesQuantity?: number
}

export function PlatformLimitInfoModal({
  isOpen,
  onDismiss,
  sitesQuantity,
  organizationId,
}: PlatformLimitInfoModalProps) {
  if (!organizationId) {
    return null
  }

  const { data } = useOrganizationPermissionsFieldsFragment(organizationId)
  const hasPermission = data?.permissions?.billing?.edit

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      aria-label="Workspace site limit warning"
    >
      <ModalCard>
        <StyledModal variant="ACTION">
          <StyledModalHeader onCloseButtonClick={() => onDismiss(false)}>
            You’ve reached your plan limit of{" "}
            <strong css={strongCss}>{sitesQuantity} sites</strong>.
          </StyledModalHeader>
          <StyledModalBody>
            {hasPermission
              ? `To add another site, upgrade the plan.`
              : `To add another site, talk to your workspace admin to upgrade to the plan.`}
            <StyledModalActions>
              <Button
                variant="SECONDARY"
                tone="NEUTRAL"
                type="button"
                onClick={() => {
                  onDismiss(false)
                }}
              >
                Dismiss
              </Button>
              <Button
                variant="PRIMARY"
                type="submit"
                onClick={() => {
                  onDismiss(false)
                  navigate(
                    hasPermission
                      ? getPathToOrgPricing(organizationId)
                      : getPathToOrgSettings(organizationId)
                  )
                }}
              >
                {hasPermission ? `Upgrade plan` : `View usage`}
              </Button>
            </StyledModalActions>
          </StyledModalBody>
        </StyledModal>
      </ModalCard>
    </Modal>
  )
}

/* styles */

const strongCss: ThemeCss = theme => ({
  color: theme.colors.purple[60],
})
