import { SimplyfiedOrg } from "./useAcceptSiteTransfer"
import { usePlatformLimitSummariesForWorkspacesQuery } from "@modules/organization/queries.generated"
import { WatchQueryFetchPolicy } from "apollo-client"

export type Options = {
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
}

export type CustomOrg = SimplyfiedOrg & {
  usage?: {
    limit?: number
    taken?: number
  }
}

export function useUserOrganizationsBySites(
  organizations: CustomOrg[] = [],
  options: Options
) {
  const { fetchPolicy = `cache-and-network`, skip = false } = options || {}
  const ids = organizations.map(org => org.id)

  const { data, loading, error } = usePlatformLimitSummariesForWorkspacesQuery({
    variables: {
      workspaceIds: ids,
    },
    fetchPolicy: fetchPolicy,
  })

  const oragnizationsWithRoom: CustomOrg[] = []
  const organizationsWithNoRoom: CustomOrg[] = []

  const platformLimits = data?.platformLimitSummariesForWorkspaces

  if (platformLimits) {
    platformLimits.forEach(orgSummaries => {
      const org = organizations.find(org => org.id === orgSummaries.workspaceId)

      const summaries = orgSummaries?.platformLimitSummaries
      const sitesData = summaries?.find(summary => summary.name === `SITES`)

      if (org) {
        org.usage = {
          limit: sitesData?.limit,
          taken: sitesData?.workspace.count,
        }
      }

      const { limit, taken } = org?.usage || {}

      if (
        typeof limit === `number` &&
        typeof taken === `number` &&
        limit !== -1 &&
        limit <= taken
      ) {
        org && organizationsWithNoRoom.push(org)
      } else {
        org && oragnizationsWithRoom.push(org)
      }
    })
  }

  return {
    withRoom: oragnizationsWithRoom,
    withNoRoom: organizationsWithNoRoom,
    loading,
    error,
  }
}
